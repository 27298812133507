<template>
  <div class="flex md:space-x-6 space-x-4 md:py-5 py-3 relative flex-center">
      <router-link :to="{ name: 'GroupDetail', params: {code: data.slug } }" class="overflow-hidden rounded-lg relative shadow-sm" :class="small ? 'md:w-32 md:h-40 w-18 h-12 ' : 'md:w-64 md:h-40 w-36 h-24 '" v-if="styles == 'feed'">
        <img :src="data.images" alt="" class="w-full h-full absolute inset-0 object-cover">
        <div class="tags" v-if="data.privacy">
          <div class="absolute privacy-label">
            {{ data.privacy }}
          </div>
        </div>
        <!-- <div class="absolute bg-blue-100 font-semibold px-2.5 py-1 rounded-full text-blue-500 text-xs top-2.5 left-2.5" v-if="styles == 'news'">JavaScript</div> -->
      </router-link>
       <router-link :to="{ name: 'DetailNewsExternal', params: {code: data.nomor } }" class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm" v-if="styles == 'news' && type === 'external'">
        <img :src="'https://fbeubaya.id/assets/img/news/'+data.image" alt="" class="w-full h-full absolute inset-0 object-cover">
      </router-link>
      <router-link :to="{ name: 'DetailNews', params: {code: data.slug } }" class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm" v-if="styles == 'news' && type === 'internal'">
        <img :src="data.images" alt="" class="w-full h-full absolute inset-0 object-cover">
      </router-link>
      <div class="flex-1 space-y-2">
          <router-link :to="{ name: 'GroupDetail', params: {code: data.slug } }" class="md:text-xl font-semibold line-clamp-2" v-if="styles == 'feed'">{{ data.title }}</router-link>
          <router-link :to="{ name: 'DetailNewsExternal', params: {code: data.nomor } }" class="md:text-xl font-semibold line-clamp-2"  v-if="styles == 'news' && type === 'external'">{{ data.title }}</router-link>
          <router-link :to="{ name: 'DetailNews', params: {code: data.slug } }" class="md:text-xl font-semibold line-clamp-2"  v-if="styles == 'news' && type === 'internal'">{{ data.title }}</router-link>
          {{ data.date }}
          <div class="leading-6 pr-4 line-clamp-2 md:block hidden ellipsis-3" v-if="styles == 'news'" v-html="data.description"></div>
            <p class="leading-6 pr-4 line-clamp-2 md:block hidden" v-if="styles == 'feed'">{{ small ? limitString(data.description, 60) : data.description }}</p>
            <div class="flex flex-wrap mt-2 space-x-2" v-if="styles == 'feed'">
                <div class="flex items-center -space-x-2 -mt-1">
                <img alt="Image placeholder" v-for="member in data.member.slice(0, 2)" :key="member.id" :src="member.user.images ? member.user.images : $placeholder" class="border-2 border-white rounded-full w-7 h-7">
                </div>
                <div class="flex-1 leading-4 text-sm">
                <div v-html="data.text_member"></div>
                </div>
            </div>
          <div class="flex items-center justify-between" v-if="styles == 'feed'">
                <div class="flex space-x-3 items-center text-sm">
                    <div>{{ data.total_post }}</div>
                    <div class="md:block hidden">·</div>
                    <div>{{ data.total_member }}</div>
                </div>
                <div class="flex space-x-3">
                    <button @click="actionJoin" class="md:flex items-center justify-center h-9 px-8 rounded-md border hidden bg-blue-600 text-white" v-if="!data.user_id && data.waiting_approval !== 1">Join</button>
                    <button class="bg-gray-200 flex flex-1 h-8 items-center px-4 justify-center rounded-md capitalize" v-if="data.waiting_approval === 1">Waiting Approval</button>
                </div>
          </div>
          <div class="flex items-center pt-3" v-if="styles == 'news'">
            <!-- <div class="flex items-center"><Icon icon="bi:hand-thumbs-up" class="text-xl mr-1" />{{ data.total_like }}</div>
            <div class="flex items-center mx-4">
                <Icon icon="ant-design:message-outlined" class="text-xl mr-1" />{{ data.total_comment }}
                <div class="-space-x-2 flex items-center ml-1">
                    <img alt="Image placeholder" v-for="member in data.member" :key="member.id" :src="member.images" class="border-2 border-white rounded-full w-7">
                </div>
            </div>
            <div class="flex items-center"><Icon icon="bi:bookmark" class="text-xl mr-1" />{{ data.total_bookmark }}  </div> -->
          </div>
      </div>
  </div>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'list_blog',
        props: ['data', 'styles', 'type', 'small'],
        components: {},
        methods: {
            actionJoin () {
                const $this = this
                axios.get('group/join/' + this.$props.data.slug).then(function (response) {
                    var titleAlert = 'Waiting Approval'
                    if (response.data.verification === 1) {
                        titleAlert = 'Success Join Group'
                    }
                    $this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: titleAlert,
                        text: response.data.message
                    }).then(function () {
                        if (response.data.verification === 1) {
                            $this.$router.push({ name: 'GroupDetail', params: { code: $this.$props.data.slug } })
                        } else {
                            $this.data.waiting_approval = 1
                        }
                    })
                }).catch(function () {
                    $this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            }
        }
    }
</script>
