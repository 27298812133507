<template>
  <div id="news">
    <transition name="slide-fade" mode="out-in">
    <div class="mcontainer" v-if="this.$route.name === 'News' ">
      <div class="md:flex justify-between relative md:mb-4 mb-3">
            <div class="flex-1">
                <h2 class="text-lg font-semibold"> News </h2>
                <p>Berita terbaru terkait keilmuan manajemen, dunia perkuliahan, dan perkembangan keilmuan dunia.</p>
                <br>
            </div>
            <router-link to="/app/news/create" v-if="datauser.is_admin === 1" class="flex d-inline items-center justify-center px-5 py-2 rounded-md bg-blue-600 text-white md:absolute right-0">
                <span> Create News </span>
            </router-link>
      </div>
        <div class="d-flex row">
            <div class="col-md-4 order-md-2 containerSticky">
                <vue-sticky-sidebar containerSelector=".containerSticky" innerWrapperSelector=".uk-sticky" :topSpacing='100'>
                    <div uk-sticky="offset:100" class="uk-sticky card p-3">
                        <div class="mb-4">
                            <div class="header_search search-news">
                                <i class="uil-search-alt"></i>
                                <input v-model="filter.search" v-on:keyup.enter="fetchSuggestion" type="text" placeholder="Search news..." autocomplete="off" class="form-control" style="min-width: 100%;">
                            </div>
                        </div>
                        <h4 class="text-lg font-semibold mb-3"> Categories <a href="javascript:void(0)" @click.prevent="addCategory" style="float: right;font-weight: 400;font-size: 12px;" v-if="datauser.is_admin === 1">+ Add</a></h4>
                        <ul class="list-checkbox" v-if="dataCategory && dataCategory.length > 0">
                            <li v-for="item in dataCategory" :key="item.id">
                                <b-form-checkbox id="checkbox-1" name="checkbox-1" :value="item.slug" unchecked-value="" v-model="checkedCategory" @change="fetchSuggestion()">
                                    {{ item.title }}
                                    <a href="javascript:void(0)" @click.prevent="editCategory(item)" class="btn-edit" v-if="datauser.is_admin === 1">Edit</a>
                                </b-form-checkbox>
                            </li>
                        </ul>
                        <p v-else class="mb-4">Category not found</p>
                        <h4 class="text-lg font-semibold mb-3"> Tags </h4>
                        <div class="flex flex-wrap gap-2" v-if="dataTags">
                            <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full item-tags" :class="tagClass(item)" @click="selectTags(item)" v-for="item in dataTags" :key="item">{{ item }}</a>
                        </div>
                        <p v-if="dataTags.length === 0" class="mb-4">Tags not found</p>
                    </div>
                </vue-sticky-sidebar>
            </div>
            <div class="col-md-8 order-md-1">
                <b-tabs content-class="mt-4">
                    <b-tab title="Hall Of Fame" active>
                        <div class="card divide-y px-4">
                            <transition name="fade" mode="out-in">
                                <div v-if="loading_hall">
                                    <ListBlog v-for="data in hall" :key="data.nomor" :data="data" :styles="'news'" :type="'internal'" />
                                </div>
                                <div v-if="!loading_hall">
                                    <content-placeholders v-for="index in 3" :key="index" class="my-3">
                                    <content-placeholders-heading :img="true" />
                                    <content-placeholders-text :lines="3" />
                                    </content-placeholders>
                                </div>
                            </transition>
                            <div v-if="loading_hall_other">
                                <content-placeholders v-for="index in 1" :key="index" class="my-3">
                                <content-placeholders-heading :img="true" />
                                <content-placeholders-text :lines="3" />
                                </content-placeholders>
                            </div>
                        </div>
                        <div class="flex justify-center mt-6" v-if="loadmore_hall_show">
                            <a href="javascript:void(0)" @click="loadmore_hall()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                                Load more</a>
                        </div>
                    </b-tab>
                    <b-tab title="News Campus">
                        <div class="card divide-y px-4">
                            <transition name="fade" mode="out-in">
                                <div v-if="loading">
                                    <ListBlog v-for="data in news" :key="data.nomor" :data="data" :styles="'news'" :type="'external'" />
                                </div>
                                <div v-if="!loading">
                                    <content-placeholders v-for="index in 3" :key="index" class="my-3">
                                    <content-placeholders-heading :img="true" />
                                    <content-placeholders-text :lines="3" />
                                    </content-placeholders>
                                </div>
                            </transition>
                            <div v-if="loading_other">
                                <content-placeholders v-for="index in 1" :key="index" class="my-3">
                                <content-placeholders-heading :img="true" />
                                <content-placeholders-text :lines="3" />
                                </content-placeholders>
                            </div>
                        </div>
                        <div class="flex justify-center mt-6" v-if="loadmore_show">
                            <a href="javascript:void(0)" @click="loadmore()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                                Load more</a>
                        </div>
                    </b-tab>
                    <b-tab title="Infinity News">
                        <div class="card divide-y px-4">
                            <transition name="fade" mode="out-in">
                                <div v-if="loading_article">
                                    <ListBlog v-for="data in article" :key="data.nomor" :data="data" :styles="'news'" :type="'internal'" />
                                </div>
                                <div v-if="!loading_article">
                                    <content-placeholders v-for="index in 3" :key="index" class="my-3">
                                    <content-placeholders-heading :img="true" />
                                    <content-placeholders-text :lines="3" />
                                    </content-placeholders>
                                </div>
                            </transition>
                            <div v-if="loading_article_other">
                                <content-placeholders v-for="index in 1" :key="index" class="my-3">
                                <content-placeholders-heading :img="true" />
                                <content-placeholders-text :lines="3" />
                                </content-placeholders>
                            </div>
                        </div>
                        <div class="flex justify-center mt-6" v-if="loadmore_article_show">
                            <a href="javascript:void(0)" @click="loadmore_article()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                                Load more</a>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
    <router-view/>
    </transition>
    <b-modal v-model="category.form.show" :title="category.form.title" ok-title="Submit" :hide-footer="true">
        <form @submit.prevent="">
            <b-form-group>
                <b-form-input
                    v-model="category.form.data.title"
                    placeholder="Add a title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group align="right">
                <div>
                    <b-button v-if="category.form.data.id" variant="danger" class="mr-2" @click="deleteCategory">
                        Delete
                    </b-button>
                    <b-button variant="primary" type="submit" @click="submitCategory" size="sm">
                        Submit
                    </b-button>
                </div>
            </b-form-group>
        </form>
    </b-modal>
  </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import ListBlog from '@/components/ListBlog'
    import axios from 'axios'
    import vueStickySidebar from 'vue-sticky-sidebar'

    export default {
        name: 'News',
        computed: {
            ...mapState('auth', { datauser: 'user' }),
            ...mapState('categories', {
                dataCategory: state => state.data,
                dataTags: state => state.tags
            })
        },
        components: {
            ListBlog,
            vueStickySidebar
        },
        data () {
            return {
                news: [],
                hall: [],
                checkedCategory: [],
                checkedTags: [],
                filter: {
                    search: ''
                },
                category: {
                    form: {
                        title: '',
                        show: false,
                        loading: false,
                        data: {
                            id: '',
                            title: '',
                            type: 'news'
                        }
                    }
                },
                loading: false,
                loading_other: false,
                loadmore_show: true,
                page: 1,
                loading_hall: false,
                loading_hall_other: false,
                loadmore_hall_show: true,
                page_hall: 1,
                loading_article: false,
                loading_article_other: false,
                loadmore_article_show: true,
                page_article: 1
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            ...mapActions('categories', {
                _createCategory: 'create',
                _updateCategory: 'update',
                _deleteCategory: 'delete',
                _fetchCategory: 'fetch'
            }),
            fetchSuggestion () {
                const $this = this
                this.loading_hall = false
                this.loadmore_hall_show = false
                this.page_hall = 1
                this.loading_article = false
                this.loadmore_article_show = false
                this.page_article = 1
                this.page = 1
                const categories = this.checkedCategory.join()
                const tags = this.checkedTags.join()
                axios.get('article?search=' + this.filter.search + '&type=hall_of_fame&page=' + this.page_hall + '&limit=5&category=' + categories + '&tags=' + tags).then(function (response) {
                    $this.hall = response.data.result.data
                    if ($this.hall.length < 5) {
                        $this.loadmore_hall_show = false
                    }
                    $this.loading_hall = true
                })
                axios.get('article?search=' + this.filter.search + '&type=article&page=' + this.page_hall + '&limit=5&category=' + categories + '&tags=' + tags).then(function (response) {
                    $this.article = response.data.result.data
                    if ($this.article.length < 5) {
                        $this.loadmore_article_show = false
                    }
                    $this.loading_article = true
                })
                axios.get('https://fbeubaya.id/api/get_news/' + this.page + '?search=' + this.filter.search).then(function (response) {
                    $this.news = response.data
                    if ($this.news.length < 5) {
                        $this.loadmore_show = false
                    }
                    $this.loading = true
                })
            },
            fetchData () {
                this.page = 1
                const $this = this
                axios.get('https://fbeubaya.id/api/get_news/' + this.page).then(function (response) {
                    $this.news = response.data
                    $this.loading = true
                })
                axios.get('article?type=hall_of_fame&page=' + this.page_hall + '&limit=5').then(function (response) {
                    $this.hall = response.data.result.data
                    if ($this.hall.length < 5) {
                        $this.loadmore_hall_show = false
                    }
                    $this.loading_hall = true
                })
                axios.get('article?type=article&page=' + this.page_hall + '&limit=5').then(function (response) {
                    $this.article = response.data.result.data
                    if ($this.article.length < 5) {
                        $this.loadmore_article_show = false
                    }
                    $this.loading_article = true
                })
                this._fetchCategory({ type: 'news' })
            },
            loadmore () {
                this.loading_other = true
                const $this = this
                this.page = this.page + 1
                axios.get('https://fbeubaya.id/api/get_news/' + this.page + '?search=' + this.filter.search).then(function (response) {
                    $this.news.push(...response.data)
                    $this.loading_other = false
                    if (response.data.length === 0) {
                        $this.loadmore_show = false
                    }
                })
            },
            loadmore_hall () {
                this.loading_hall_other = true
                const $this = this
                this.page_hall = this.page_hall + 1
                const categories = this.checkedCategory.join()
                const tags = this.checkedTags.join()
                axios.get('article?search=' + this.filter.search + '&type=hall_of_fame&page=' + this.page_hall + '&limit=5&category=' + categories + '&tags=' + tags).then(function (response) {
                    $this.hall.push(...response.data.result.data)
                    $this.loading_hall_other = false
                    if (response.data.result.data.length === 0) {
                        $this.loadmore_hall_show = false
                    }
                })
            },
            loadmore_article () {
                this.loading_article_other = true
                const $this = this
                this.page_article = this.page_article + 1
                const categories = this.checkedCategory.join()
                const tags = this.checkedTags.join()
                axios.get('article?search=' + this.filter.search + '&type=article&page=' + this.page_hall + '&limit=5&category=' + categories + '&tags=' + tags).then(function (response) {
                    $this.article.push(...response.data.result.data)
                    $this.loading_article_other = false
                    if (response.data.result.data.length === 0) {
                        $this.loadmore_article_show = false
                    }
                })
            },
            submitCategory () {
                this.category.form.loading = true
                const { id, title, type } = this.category.form.data
                const promise = id ? this._updateCategory({ id, data: { title, type } }) : this._createCategory({ title, type })
                promise.then(() => {
                    this._fetchCategory({ type: 'news' })
                    this.category.form.show = false
                    this.category.form.data = { id: '', title: '' }
                })
                    .finally(() => {
                        this.category.form.loading = false
                    })
            },
            addCategory () {
                this.category.form.title = 'Add Category'
                this.category.form.show = true
                this.category.form.data.id = ''
                this.category.form.data.title = ''
                this.category.form.data.type = 'news'
            },
            editCategory (item) {
                this.category.form.title = 'Edit Category'
                this.category.form.show = true
                this.category.form.data = { ...item }
            },
            deleteCategory () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.category.form.loading = true
                        const { id } = this.category.form.data
                        this._deleteCategory(id)
                            .then(() => {
                                this._fetchCategory({ type: 'news' })
                                this.category.form.show = false
                                this.category.form.data = { id: '', title: '' }
                                this.$swal(
                                    'Deleted!',
                                    'Your category has been deleted.',
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.category.form.loading = false
                            })
                    }
                })
            },
            tagClass (tag) {
                const isSelected = this.checkedTags.includes(tag)
                return {
                    active: isSelected,
                    '': !isSelected
                }
            },
            selectTags (tag) {
                const index = this.checkedTags.findIndex(t => t === tag)
                if (index >= 0) {
                    this.checkedTags.splice(index, 1)
                } else {
                    this.checkedTags.push(tag)
                }
                this.fetchSuggestion()
            }
        }
    }
</script>
